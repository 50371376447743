
.column-component {
  width: auto;

  border-radius: 12px;
  border: 1px solid $colorGray300;
  background: $colorWhite;
  //padding: 3rem;
  display: flex;
  flex-direction: column;
  //cursor: pointer;
  //width: 100rem;
  //margin: 0 0 0 50rem;
  cursor: pointer;
  .top-line {
    display: flex;
    //justify-content: space-between;
    gap: 1.5rem;
    align-items: center;
    padding: 2rem 4.5rem;
    border-bottom: 1px solid $colorGray300;
    .title {
      color: $colorGray700;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 600;
    }
    .title-divide-bar {
      width: 0.25rem;
      height: 2.5rem;
      background-color: $colorGray300;
    }
    .product-icon {
      background-color: $colorGray300;
      border-radius: 0.5rem;
      padding: 0.25rem;
      width: 3.5rem;
      height: 3.5rem;
    }
    .product-name {
      color: $colorGray700;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 600;
    }

  }

  .content-container {
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 3rem 0 3rem 4.5rem;
    gap: 3rem;
    .tag-wrapper {
      display: flex;
      gap: 1.5rem;
    }
    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: auto;
      overflow-x: hidden;
      //.content {
      //  display:-webkit-box;
      //  -webkit-line-clamp: 2;
      //  -webkit-box-orient:vertical;
      //  overflow: hidden;
      //  color: $colorBlack;
      //  text-overflow: ellipsis;
      //  font-size: 20px;
      //  font-style: normal;
      //  font-weight: 400;
      //}
      .ux-evaluation-tag {
        display: none;
      }
      .img-wrapper {
        display: flex;
        gap: 3rem;
        overflow: hidden;
        white-space: nowrap;
        align-items: center;
        .img-container {
          position: relative;
          width: 24.5rem;  /* 이미지 크기 고정 */
          height: auto;
          min-height: 50rem;
          .preview-img {
            transform: translateZ(0);
            will-change: transform;

            border-radius: 1.5rem;
            width: 24rem;
            height: fit-content;
            min-height: 50rem;
            border: 0.25rem solid $colorGray300; /* $colorGray300 */
            transition: opacity 0.5s ease-in-out;
          }

        }


        .preview-img.hidden {
          opacity: 0;
        }

        .preview-img.visible {
          opacity: 1;
        }

        /* 원형 스피너 스타일 */
        .spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 5rem;
          height: 5rem;
          border: 0.5rem solid rgba(0, 0, 0, 0.1);
          border-top: 0.5rem solid #3498db;
          border-radius: 50%;
          //transform: translate(-50%, -50%);
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          to {
            transform: rotate(360deg);
          }
        }
        //img {
        //  border-radius: 1.5rem;
        //  width: 24.5rem;  // 이미지 크기 고정
        //  height: fit-content;
        //  //flex-shrink: 0;  // 이미지가 줄어들지 않도록 설정
        //  border: 0.25rem solid $colorGray300;
        //}
      }
    }
  }

  .author-wrapper {
    border-top: 1px solid $colorGray300;
    padding: 2.5rem 4.5rem;
    display: flex;
    align-items: center;
    gap: 2.75rem;
    .author-title {
      display: flex;
      align-items: center;
      gap: 1rem;
      .author-text {
        font-size: 2rem;
        font-weight: 400;
        color: $colorGray700;
      }
      .author-name {
        font-size: 2.25rem;
        font-weight: 600;
        color: $colorGray700;
      }
    }
    .author-contact-box{
      display: flex;
      align-items: center;
      gap: 1.25rem;

      //.author-contact-wrapper {
      //  display: flex;
      //  align-items: center;
      //  gap: 0.25rem;
      //  font-size: 1.75rem;
      //  color: $colorGray700;
      //  img {
      //    width: 2rem;
      //  }
      //  .link-href:hover {
      //    text-decoration-line: underline;
      //    cursor: pointer;
      //  }
      //}
    }

    .author-contact-mobile-box {
      display: none;
      .show-author-contact-btn {
        display: none;
      }
    }
  }
  &:hover {
    background-color: #1D293905;
  }
  &:active {
    background-color: #1D293905;
  }

  @include not-pc {
    .top-line {
      padding: 2rem;
      .title {
        max-width: 50%;
        word-wrap: break-word;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 1;
        //line-clamp: 1;
        //-webkit-box-orient: vertical;
      }
      .ux-evaluation-tag {
        display: none;
      }
      .title-divide-bar {
        height: 100%;
      }
      .product-name {
        max-width: 50%;
        word-wrap: break-word;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 1;
        //line-clamp: 1;
        //-webkit-box-orient: vertical;
      }
    }

    .content-container {
      padding: 2rem;
      .content-wrapper {
        align-items: flex-start;
        .ux-evaluation-tag {
          display: flex;
        }
        .img-wrapper {
          .img-container {
            height: auto;
            min-height: 50rem;
            .preview-img {
              height: auto;
            }

          }
        }
      }
    }

    .author-wrapper {
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      .author-contact-box {
          display: none;
      }
      .author-contact-mobile-box {
        display: flex;
        .show-author-contact-btn {
          display: flex;

        }
      }
    }

    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: #1D293905;
    }
  }
}
