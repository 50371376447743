$colorWhite: #ffffff;
$colorBlack: #000000;

//$colorPurple100: #7025B6;
//$colorPurple200: #34085D;
//$colorPurple300: #BD88EE;
//
//
//$colorGray100: #BEBEBE;
//$colorGray110: #D9D9D9;
//$colorGray120: #8B8A8A;
//$colorGray130: #EEEEEE;
//$colorGray140: #969696;
//$colorGray150: #C8C8C8;
//$colorGray160: #898989;
//$colorGray170: #C1C1C1;
//$colorGray180: #F1F1F1;
//$colorGray190: #E3E3E3;
//
//$colorBlue100: #7492AD;
//
//$zIndexModal: 10;
//$zIndexDim: 5;
$colorGray50: #F7FAFC;
$colorGray100: #EDF2F7;
$colorGray200: #E2E8F0;
$colorGray300: #CBD5E0;
$colorGray400: #A0AEC0;
$colorGray500: #718096;
$colorGray600: #4A5568;
$colorGray700: #344054;
$colorGray800: #1A202C;
$colorGray900: #101828;

$blackAlpha200: rgba(0, 0, 0, 0.08);

$cyan500: #00B5D8;



$colorOrange50: #FFF6ED;
$colorOrange600: #EC4A0A;

$colorBlue50: #EFF8FF;
$colorBlue500: #3182CE;
$colorBlue600: #1570EF;
$colorBlue700: #175CD3;

$colorRose100: #FFE4E8;
$colorRose600: #E31B54;

$colorPurple600: #6938EF;
$colorPurple50: #F4F3FF;

$colorWarning25: #FFFCF5;
$colorWarning500: #F79009;

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin min-tablet {
  @media (min-width: 768px) and (max-width: 1019px) {
    @content;
  }
}

@mixin not-pc {
  @media (max-width: 1019px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 1020px) and (max-width: 1119px) {
    @content;
  }
}

@mixin big-tablet {
  @media (min-width: 1120px) and (max-width: 1300px) {
    @content;
  }
}


@mixin min-pc {
  @media (min-width: 1300px) and (max-width: 1490px) {
    @content;
  }
}


@mixin pc {
  @media (min-width: 1491px) and (max-width: 99999px) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content
  }
}
