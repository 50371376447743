.no-articles-container {
  width: 100%;
  height: 35.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  .no-articles-title {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    line-height: 4rem;
    color: $colorGray700;
  }
  .no-articles-sub {
    font-size: 2.25rem;
    font-weight: 500;
    text-align: center;
    line-height: 3.5rem;
    color: $colorGray400;
  }
}
