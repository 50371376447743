.filtered-search-wrapper {
  background-color: $colorGray200;
  border: 0.125rem solid $colorGray500;
  gap: 2.5rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  padding: 2rem 2rem;
  flex-direction: row;
  flex-wrap: nowrap;
  position: sticky;
  //top: 10rem;

  &.fixed {
    position: fixed;
    top: 2rem;
    left: var(--dynamic-left, 0);
    width: calc(var(--dynamic-width, 100%) - 4.25rem);
    z-index: 100;
    background-color: rgba($colorGray200, 0.95);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }

  .reset-container {
    display: flex;
    align-items: center;
    font-size: 1.75rem;
    font-weight: 400;
    gap: 0.25rem;
    cursor: pointer;
    padding: 1rem 1.25rem;
    border-radius: 0.75rem;
    div {
      width: max-content;
    }
    &:hover {
      background-color: $colorGray50;
    }
    &:active {
      background-color: $colorGray100;
    }
  }
  .filtered-container {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    flex-direction: row;
    width: fit-content;
    flex-wrap: wrap;
  }
}


.filter-tag-component {
  height: 4rem;
  background-color: $colorWhite;
  border: 0.125rem solid $colorGray300;
  border-radius: 0.75rem;
  padding: 0 1.25rem;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  img {
    width: 2rem;
  }
  .filter-tag-title {
    font-size: 1.75rem;
    font-weight: 400;
    color: $colorGray700;
  }
  .delete-this-tag {
    cursor: pointer;
  }
  cursor: pointer;

  &:hover {
    background-color: $colorGray50;
  }
  &:active {
    background-color: $colorGray100;
  }
}
