.login-admin {
  margin: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .login-form {
    padding: 20rem;
    background-color: $colorWhite;
    border-radius: 1.5rem;
    border: 1px solid $colorGray300;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    color: $colorBlack;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    .title {
      padding: 0 0 5rem 0;
      display: flex;
      justify-content: center;

      color: $colorBlack;
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 38.4px */
    }
  }
  input {
    width: 50rem;
    padding: 1.25rem 2rem;
    border-radius: 0.75rem;
    border: 1px solid $colorGray200;
    background: $colorWhite;
    color: $colorBlack;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem; /* 155.556% */
  }
  input:focus {
    outline: none;
  }
  .submit-btn {
    outline: none;
    background: $colorBlue500;
    width: 100%;
    border: 0;
    border-radius: 1rem;
    padding: 1.5rem 2.5rem;
    color: $colorWhite;
    font-family: inherit;
    font-size: inherit;
    font-weight: 500;
    line-height: inherit;
    text-transform: uppercase;
    cursor: pointer;

  }
  .error {
    color: darkred;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem; /* 155.556% */
  }
}