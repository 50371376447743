
//form {
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  align-items: center;
//  gap: 2rem;
//}

.article-form {
  width: 80%;
  margin: 0 auto;
  padding: 2rem;
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 15px;
    font-size: 2rem;
    select,
    textarea {
      width: 100%;
      padding: 10px;
      margin-top: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
  input {
    width: 80rem;
    padding: 1.25rem 2rem;
    border-radius: 0.75rem;
    border: 1px solid $colorGray200;
    background: $colorWhite;
    color: $colorBlack;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem; /* 155.556% */
  }
  input:focus {
    outline: none;
  }
  .content-box {
    height: 50rem;
  }
  .article-link-input {
    display: flex;
    align-items: center;
    input {
      flex-grow: 1;
    }
    button {
      margin-left: 10px;
      background-color: red;
      color: white;
      border: none;
      padding: 5px 10px;
      cursor: pointer;
    }
  }

  button[type="button"] {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid $colorGray700;
    //background-color: $colorGray700;
    //color: white;
    border-radius: 4px;
    cursor: pointer;
  }

  button[type="submit"] {
    margin-top: 20px;
    padding: 10px;
    background-color: $colorBlue600;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
}