.searched-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .search-component {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    padding:0.75rem 1.25rem;

    border-radius: 3rem;
    border: 1px solid $colorGray200;
    background: $colorGray50;

    .search-icon {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0 0 0 0.75rem;
    }

    .search-bar {
      //padding: 0 1.25rem 0 2rem;
      padding: 0;
      width: 100%;
      border-radius: 0.75rem;
      border: none;
      background: transparent;
      color: $colorGray600;
      font-family: Pretendard;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 400;
    }

    .search-bar:focus {
      outline: none;
      color: $colorGray600;
    }
    .search-btn {
      cursor: pointer;
      background-color: #344054;
      opacity: 0.3;
      width: 4.5rem;
      height: 4.5rem;
      border: none;
      border-radius: 100rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }


  }
  .search-tag-wrapper {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .search-tag {
      display: flex;
      padding: 0.75rem 1.25rem;
      align-items: center;
      gap: 1rem;
      border-radius: 0.75rem;
      border: 1px solid $colorGray400;

      .text {
        color: $colorGray600;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 500;
      }

      img {
        cursor: pointer;
      }
    }
  }
}