.show-filter-btn-for-mobile {
  display: none;


  &:active {
    background-color: $colorGray100;
  }

  @include not-pc {
    height: 2.5rem;
    background-color: $colorWhite;
    border: 0.125rem solid $colorGray300;
    border-radius: 0.75rem;
    padding: 1.25rem;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;
    img {
      width: 2rem;
    }
    .filter-tag-title {
      font-size: 2rem;
      font-weight: 500;
      color: $colorGray700;
      line-height: 3rem;
    }
  }
}
