.home-page {
  padding: 0 4.5rem;
  box-sizing: border-box;
  overflow: auto;
  .title-and-sort-container {
    margin: 2rem 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .bottom-view {
    display: flex;
    gap: 4.5rem;
    overflow-x: auto;
    //justify-content: space-between;

    .filter-right-view {
      width: 99%;
      overflow-x: auto;
      padding: 4.5rem 0 0 0;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .filtered-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .result-num {
          font-size: 1.75rem;
          font-weight: 500;
        }
      }

      .colum-list {
        display: flex;
        flex-direction: column;
        gap: 4.5rem;
        width: 100%;
        //padding: 4rem 0 0 0;

        a {
          color: inherit;
          text-decoration: none;
        }

        .searched {
          color: $colorGray500;

          font-size: 1.75rem;
          font-style: normal;
          font-weight: 500;
        }
      }
    }
  }

  @include not-pc {
    padding: 2rem;

    .bottom-view {

      .filter-right-view {
        padding: 0;
      }
    }
  }
}
