.admin-page {
  .article-title-wrapper {
    display: flex;
    justify-content: space-between;
    .title {
      color: $colorBlack;
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 38.4px */
    }

    .add-column-btn {
      padding: 1.5rem;
      text-decoration: none;
      background-color: $colorBlue500;
      color: $colorWhite;

      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 38.4px */

      outline: none;
      border: 0;
      border-radius: 1rem;
      cursor: pointer;
    }
  }
}