.ux-keyword {
  border-radius: 0.75rem;
  background: var(--blue-500, #3182CE);
  display: flex;
  height: 3rem;
  padding: 0 1.25rem;
  align-items: center;
  gap: 0.25rem;


  .keyword {
    color: var(--white, #FFF);
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.5rem; /* 142.857% */
  }
}

.product {
  border-radius: 0.75rem;
  background: var(--blue-100, #BEE3F8);
  display: flex;
  height: 3rem;
  padding: 0 1.25rem;
  align-items: center;
  gap: 0.25rem;


  .name {
    color: var(--gray-800, #1A202C);
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.5rem; /* 142.857% */
  }
}

.ui-component {
  border-radius: 0.75rem;
  background: $colorOrange50;
  display: flex;
  height: 3rem;
  padding: 0 1.25rem;
  align-items: center;
  gap: 0.25rem;


  .name {
    font-size: 1.75rem;
    color: $colorOrange600;
    font-weight: 400;
  }
}

.category-tag {
  border-radius: 0.75rem;
  background: $colorBlue50;
  display: flex;
  height: 3rem;
  padding: 0 1.25rem;
  align-items: center;
  gap: 0.25rem;
  .name {
    font-size: 1.75rem;
    color: $colorBlue600;
    font-weight: 400;
  }
}

.pattern-tag {
  border-radius: 0.75rem;
  background: $colorRose100;
  display: flex;
  height: 3rem;
  padding: 0 1.25rem;
  align-items: center;
  gap: 0.25rem;
  .name {
    font-size: 1.75rem;
    color: $colorRose600;
    font-weight: 400;
  }
}

.ux-evaluation-tag {
  border-radius: 0.75rem;
  display: flex;
  height: 3rem;
  padding: 0 1.25rem;
  align-items: center;
  gap: 0.25rem;

  &.good {
    background: $colorBlue50;
    .name {
      color: $colorBlue600;
    }
  }

  &.bad {
    background: $colorRose100;
    .name {
      color: $colorRose600;
    }
  }

  .name {
    font-size: 1.75rem;
    font-weight: 400;
  }
}
