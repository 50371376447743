.share-btn-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid $colorGray300;
  border-radius: 0.75rem;
  height: 4rem;
  padding: 0 1.25rem;
  gap: 0.25rem;
  text-decoration: none;
  img {
    width: 2rem;
    height: 2rem;
  }
  .share-text {
    color: $colorGray600;
    font-size: 1.75rem;
    font-weight: 400;
  }

  &:hover {
    background-color: $colorGray50;
  }
  &:active {
    background-color: $colorGray100;
  }
}
