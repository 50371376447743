.show-author-contact-btn {
  width: 4rem;
  height: 4rem;
  .contact-menu-btn {
    width: 2rem;
    height: 2rem;
    padding: 1rem;
    border-radius: 0.75rem;
    border: 1px solid $colorGray300;
    background: $colorWhite;
    .drop-down-btn {
      background: none;
      border: none;
      padding: 0;
    }
    &:active {
      background-color: $colorGray300;
    }
  }

  .drop-down-list {
    position: relative;
    height: fit-content;
    width: fit-content;
    transform: translateY(-100%);
    right: 16.5rem;
    bottom: 2rem;

    padding: 0.5rem 0;
    gap: 0.5rem;
    border-radius: 1rem;
    border: 1px solid $colorGray300;
    background: $colorWhite;

    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    .drop-down-element {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      justify-content: flex-start;
      padding: 1rem 1.5rem;

      .drop-down-text {
        color: $colorGray600;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.5rem;
        text-wrap: nowrap;
      }

      &:active {
        background-color: $colorGray100;
      }
    }
  }
}
