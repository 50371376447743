.detail-page {
  position: fixed;
  width: 95%;
  max-width: 180rem;
  height: 90%;
  top:  50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.5rem;
  border: 1px solid $colorGray300;
  background: $colorWhite;
  display: flex;
  flex-direction: column;
  z-index: 10;
  .top-line {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $colorGray300;
    align-items: center;
    padding: 2.5rem 4.5rem;
    .top-left {
      display: flex;
      gap: 1.5rem;
      align-items: center;
      height: 100%;
      .title {
        color: $colorBlack;
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 700;
      }

      .title-divide-bar {
        width: 0.25rem;
        height: 2.5rem;
        background-color: $colorGray300;
      }

      .product-icon {
        background-color: $colorGray300;
        border-radius: 0.5rem;
        padding: 0.25rem;
        width: 3.5rem;
        height: 3.5rem;
      }

      .product-name {
        color: $colorGray700;
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 600;
      }
    }
    .top-right {
      display: flex;
      gap: 1.5rem;
      align-items: center;
      .close-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5rem;
        height: 1.5rem;
        padding: 1.25rem;
        cursor: pointer;
        border-radius: 999px;
        border: 1px solid $colorGray500;
        background: $colorWhite;

        .close-btn-img {
          width: 2rem;
          height: 2rem;
        }
        &:hover {
          background-color: $colorGray50;
        }
        &:active {
          background-color: $colorGray100;
        }
      }

    }

  }

  .center-content {
    overflow-y: scroll;

    .content-container {
      display: flex;
      padding: 4.5rem;

      .content-left {
        width: 66%;
        max-width: 90.75rem;
        color: #666666;
        font-size: 2rem;
        line-height: 3rem;
        font-weight: 500;
        text-align: left;
        word-break: keep-all;
        white-space: pre-wrap;
      }

      .divide-line {
        width: 0.125rem;
        background-color: $colorGray300;
        margin: 0 2rem;
      }

      .article-link {
        width: 33%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .article-link-title {
          font-size: 2rem;
          font-weight: 600;
        }

        .article-wrapper {
          display: flex;
          align-items: center;
          gap: 1rem;

          a {
            font-size: 1.75rem;
            font-weight: 500;
            letter-spacing: -0.04em;
            color: #666666;
            text-decoration: underline;
          }
        }
      }

      .more-content {
        display: none;
      }
    }

    .divide-vertical-line {
      border-bottom: 1px solid $colorGray300;
      margin: 0 0 4.5rem 0;
    }

    .tag-wrapper {
      display: flex;
      padding: 2.25rem 4.5rem;
      gap: 1.5rem;
    }

    .ux-evaluation-tag-mobile {
      overflow-x: scroll;
      white-space: nowrap;
      scroll-behavior: smooth;

      -ms-overflow-style: none; /* 인터넷 익스플로러 */
      scrollbar-width: none; /* 파이어폭스 */
      &::-webkit-scrollbar {
        display: none;
      }
      .ux-evaluation-tag {
        display: none;
      }
      .img-slider-container {
        display: flex;
        align-items: flex-start;
        position: relative;

        .img-wrapper {
          margin: 0 4.5rem;
          display: flex;
          gap: 3rem;
          padding-bottom: 2.25rem;
          max-width: 100%;
          overflow-x: scroll;
          white-space: nowrap;
          scroll-behavior: smooth;

          -ms-overflow-style: none; /* 인터넷 익스플로러 */
          scrollbar-width: none; /* 파이어폭스 */
          &::-webkit-scrollbar {
            display: none;
          }

          .image-desc-wrapper {
            //position: relative;

            display: flex;
            flex-direction: column;
            //justify-content: space-between;
            align-items: center;
            gap: 2.5rem;

            .screen-image {
              border-radius: 1.5rem;
              width: 35.25rem;
              border: 0.25rem solid $colorGray300;
            }

            .img-desc-box {
              background-color: $colorWarning25;
              border: 1px solid $colorWarning500;
              border-radius: 1.5rem;
              padding: 3rem;
              width: 29.25rem;
              display: flex;
              flex-direction: column;
              gap: 0.75rem;

              .img-desc-title-box {
                display: flex;
                gap: 0.75rem;
                align-items: center;

                .ux-insight-icon {
                  width: 3rem;
                }

                .insight-notes-title {
                  font-size: 2rem;
                  font-weight: 700;
                  color: $colorBlack;
                }
              }

              .desc {
                font-size: 2rem;
                font-weight: 400;
                word-break: keep-all;
                white-space: pre-wrap;
                line-height: 3rem;
              }
            }


            /* 원형 스피너 스타일 */
            .spinner {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 5rem;
              height: 5rem;
              border: 0.5rem solid rgba(0, 0, 0, 0.1);
              border-top: 0.5rem solid #3498db;
              border-radius: 50%;
              //transform: translate(-50%, -50%);
              animation: spin 1s linear infinite;
            }

            @keyframes spin {
              to {
                transform: rotate(360deg);
              }
            }

            .screen-image.hidden {
              opacity: 0;
            }

            .screen-image.visible {
              opacity: 1;
            }
          }
        }

        .left-arrow, .right-arrow {
          background-color: $colorWhite;
          border: 1px solid $colorGray500;
          border-radius: 50rem;
          width: 5.5rem;
          height: 5.5rem;
          cursor: pointer;
          position: absolute;
          top: 30%;
          transform: translateY(-30%);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .left-arrow {
          left: 4.5rem;
          box-shadow: -4px 4px 8px -2px #1018281A;

          img {
            transform: scale(-1, 1);
          }
        }

        .right-arrow {
          right: 4.5rem;
          box-shadow: -4px 4px 8px -2px #1018281A;

          &:hover {
            background-color: $colorGray50;
          }

          &:active {
            background-color: $colorGray100;
          }
        }
      }
    }

    .article-link-container-for-mobile {
      display: none;
    }
  }

  .author-wrapper {
    border-top: 1px solid $colorGray300;
    padding: 2.5rem 4.5rem;
    display: flex;
    align-items: center;
    gap: 2.75rem;
    .author-title {
      display: flex;
      align-items: center;
      gap: 1rem;
      .author-text {
        font-size: 2rem;
        font-weight: 400;
        color: $colorGray700;
      }
      .author-name {
        font-size: 2.25rem;
        font-weight: 600;
        color: $colorGray700;
      }
    }
    .author-contact-box{
      display: flex;
      align-items: center;
      gap: 2.5rem;

      //.author-contact-wrapper {
      //  display: flex;
      //  align-items: center;
      //  gap: 0.25rem;
      //  font-size: 1.75rem;
      //  color: $colorGray700;
      //  img {
      //    width: 2rem;
      //  }
      //  .link-href:hover {
      //    text-decoration-line: underline;
      //    cursor: pointer;
      //  }
      //}
    }

    .author-contact-mobile-box {
      display: none;
      .show-author-contact-btn {
        display: none;
      }
    }
  }

  @include not-pc {
    width: 100%;
    top: 3rem;
    transform: translate(-50%, 0);
    height: 97%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: calc(2rem + env(safe-area-inset-bottom));
    .top-line {
      padding: 2.25rem 2rem;

      .top-left {
        width: 90%;
        .title {
          max-width: 50%;
          word-wrap: break-word;
          //overflow: hidden;
          //text-overflow: ellipsis;
          //display: -webkit-box;
          //-webkit-line-clamp: 1;
          //line-clamp: 1;
          //-webkit-box-orient: vertical;
        }
        .title-divide-bar {
          height: 100%;
        }
        .ux-evaluation-tag {
          display: none;
        }
        .product-name {
          max-width: 50%;
          white-space: normal;
          word-wrap: break-word;
          //overflow: hidden;
          //text-overflow: ellipsis;
          //display: -webkit-box;
          //-webkit-line-clamp: 1;
          //line-clamp: 1;
          //-webkit-box-orient: vertical;
        }
      }
      .top-right {
        .share-btn-box {
          display: none;
        }
        .close-btn {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }

    .center-content {
      overflow-y: unset;

      .content-container {
        padding: 2rem;
        flex-direction: column;

        .content-left {
          width: 100%;
          overflow: hidden;


          &.collapsed {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          &.expanded {
            display: block;
            -webkit-line-clamp: unset;
          }
        }

        .article-link {
          display: none;
        }

        .more-content {
          display: flex;
          width: 100%;
          justify-content: center;
          padding: 1rem 0 0 0;
          color: $colorBlue600;
          font-size: 1.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: 2.5rem;
          text-decoration-line: underline;
          background: none;
          border: none;
          cursor: pointer;


          &:active {
            color: $colorBlue700;
          }
        }
      }

      .divide-vertical-line {
        margin: 0 2rem;
      }

      .ux-evaluation-tag-mobile {
        display: flex;
        flex-direction: column;
        margin: 2rem;
        gap: 2rem;
        align-items: flex-start;
        .ux-evaluation-tag {
          display: flex;
        }

        .img-slider-container {
          .img-wrapper {
            margin: 0;
          }

          .left-arrow, .right-arrow {
            display: none;
          }
        }
      }

      .article-link-container-for-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem;
        border-top: 1px solid $colorGray300;

        .article-link {
          width: 100%;
          padding: 2rem 0 0 0;
          .article-link-title {
            color: $colorBlack;
            font-size: 2rem;
            font-weight: 600;
            line-height: 2.5rem;
          }
          .article-wrapper {
            display: flex;
            align-items: center;
            padding: 1rem;
            gap: 1.125rem;
            a {
              color: #666;
              font-size: 1.75rem;
              font-style: normal;
              font-weight: 500;
              word-break: break-all;
              text-decoration-line: underline;
            }
          }
        }
      }
    }


    .author-wrapper {
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      .author-contact-box {
        display: none;
      }
      .author-contact-mobile-box {
        display: flex;
        .show-author-contact-btn {
          display: flex;

        }
      }
    }
  }
}
