.header {
  margin: 2.25rem 4rem;
  display: flex;
  gap: 2.5rem;
  justify-content: space-between;
  align-items: start;
  a{
    text-decoration: none;
    color: inherit;
    .logo {
      font-size: 3rem;
      font-style: normal;
      font-weight: 700;
    }
  }

  .study-form-link-btn {
    display: flex;
    padding: 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.75rem;
    background: $colorGray900;
    div {
      color: $colorWhite;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 600;
    }
    img {
      width: 2rem;
      height: 2rem;
    }
    &:hover {
      background-color: $colorGray800;
    }
    &:active {
      background-color: $colorGray700;
    }
  }
  @include not-pc {
    margin: 2.25rem 2.5rem;
  }
}
.bottom-line {
  margin: 2.5rem 0 0 0;

  height: 1px;
  background: var(--gray-400, #A0AEC0);
}
