html {
  /* 루트 요소 폰트 사이즈 */
  /* 1rem = 8px */
  font-size: 8px;
  @include pc {
    font-size: 8px;
  }
  //@include min-pc {
  //  font-size: 7px;
  //}
  //@include big-tablet {
  //  font-size: 6px;
  //}
  //@include tablet {
  //  font-size: 5px;
  //}
  //@include min-tablet {
  //  font-size: 4.5px;
  //}
  //@include mobile {
  //  font-size: 4px;
  //}
  body {
    font-family: Pretendard;
    background-color: $colorWhite;
    margin: 0;
  }
  //#root {
  //  max-width: 180rem;
  //}
  .dim {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorBlack;
    opacity: 0.4;
    z-index: 2;
  }
  .mobile-dim {
    position: fixed;
    overflow-y: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorBlack;
    opacity: 0.4;
    z-index: 800;
  }
  .no-scroll {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
  }


}
