.keyword-filter {
  //position: fixed;
  height: 100%;
  display: flex;
  //min-width: 32.5rem;
  //padding: 3rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  //border-radius: 1.5rem;
  //border-right: 1px solid $colorGray300;
  //background: $colorWhite;
  padding: 4.5rem 0 0 0;

  .menu-title {
    display: none;
  }
  .keyword-filter-title {
    font-size: 1.75rem;
    font-weight: 700;
  }
  .category-box {
    min-width: 32.5rem;
    border: 0.125rem solid $colorGray300;
    border-radius: 1.5rem;
    padding: 3rem 3rem 2rem 3rem;
    .big-category {
      display: flex;
      gap: 1rem;
      font-size: 2rem;
      font-weight: 600;
    }

    .small-category-list {
      //margin-left: 2.75rem;
      display: flex;
      padding: 2rem 0;
      flex-direction: column;
      //gap: 2rem;

      .small-category {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 1rem 3.75rem;
        border-radius: 1rem;
        cursor: pointer;

        &:hover {
          background-color: $colorGray50;
        }
        &:active {
          background-color: $colorGray100;
        }
      }
    }

    //.selected-box {
    //  display: flex;
    //  justify-content: center;
    //  align-items: center;
    //  width: 2.5rem;
    //  height: 2.5rem;
    //  border-radius: 0.25rem;
    //  border: 1px solid $cyan500;
    //  background: $colorGray800;
    //  cursor: pointer;
    //
    //  .ignore {
    //    background: $colorWhite;
    //    width: 1.25rem;
    //    height: 0.25rem;
    //  }
    //}

    //.non-selected-box {
    //  width: 2.5rem;
    //  height: 2.5rem;
    //  border-radius: 0.25rem;
    //  border: 2px solid $colorGray200;
    //  cursor: pointer;
    //}

    .category-name {
      color: $colorGray800;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 400;
    }

    .number-of-post {
      margin: 0 0.5rem;
      display: flex;
      padding: 0.25rem 0.5rem;
      justify-content: flex-end;
      align-items: center;
      gap: 1.25rem;
      border-radius: 0.25rem;
      background: $colorGray100;

      color: $colorGray800;
      font-family: Inter;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2rem; /* 133.333% */
    }

    .more-content {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 1rem 0 0 0;
      color: $colorBlue500;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 400;
      text-decoration-line: underline;
      background: none;
      border: none;
      cursor: pointer;

      &:active {
        color: $colorBlue700;
      }
    }

    .divide-line {
      width: 100%;
      height: 0.125rem;
      background-color: $colorGray200;
    }

    .divide-line:nth-last-child(1) {
      display: none;
    }
  }

  input[type="checkbox"]{
    display: none;
  }
  input[type="checkbox"] + label {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.25rem;
    border: 2px solid $colorGray200;
    cursor: pointer;
    user-select: none;
    font-family: "Arial", sans-serif;
  }
  input[type="checkbox"]:checked + label{
    content:'✔';
    width: 3rem;
    height: 3rem;
    border: none;
    background-image: url("CheckedBox.svg");
    background-size: cover;
  }

  input[type="checkbox"]:indeterminate + label {
    //background-color: $colorGray900;
    content: "-";
    //color: white;
    background-image: url("CheckedBoxSome.svg");
    background-size: cover;

    width: 3rem;
    height: 3rem;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    border: none;
    //font-weight: 900;
  }

  input[type="checkbox"]:indeterminate + label::before {
  //  content: "−";
  //  font-size: 2rem;
  //  font-weight: 900;
  //  line-height: 1.2;
  }

  @include not-pc {
    display: none;
    &.mobile {
      display: none;
    }

    &.mobile.open {
      padding: 0;
      overflow-y: scroll;
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      width: 80%;
      height: 100%;
      background-color: white;
      z-index: 1000;

      .menu-title {
        position: fixed;
        width: calc(80% - 4rem);
        background: $colorWhite;
        display: flex;
        padding: 2rem;
        justify-content: space-between;

        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 3rem;

        border-bottom: 1px solid $colorGray300;
        .title-text {
          color: $colorGray800;
        }
        .done-btn {
          color: $colorBlue600;
          &:active {
            color: $colorBlue700;
          }
        }
      }
      .for-padding-top {
        padding: 7rem 0 0 0;
      }
      .category-box {
        border-bottom: solid $colorGray300;
        border-width: 0 0 1px 0;
        border-radius: 0;
        .small-category-list {
          .small-category {
            &:hover {
              background-color: transparent;
            }
            &:active {
              background-color: $colorGray100;
            }
          }
        }
        .more-content {
          padding: 0;
        }
      }
    }
  }
}
