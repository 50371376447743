.sort-drop-down {
  position: relative;
  .drop-down-btn {
    padding: 0.625rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;

    border-radius: 8px;
    background: $colorWhite;
    border: 0.125rem solid $colorGray300;
    color: $colorGray600;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    img {
      width: 0.875rem;
    }
  }
  .drop-down-btn:hover {
    background-color: $colorGray50;
  }
  .drop-down-btn:active {
    background-color: $colorGray100;
  }
  .drop-down-btn-active {
    background-color: $colorGray100;
  }

  .drop-down-list {
    position: absolute;
    top: 4.5rem;
    min-width: 10.375rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 1rem;
    background: $colorWhite;
    box-shadow: 0 0.5rem 0.75rem -0.125rem rgba(0, 0, 0, 0.10), 0rem 0.25rem 0.5rem -0.125rem rgba(0, 0, 0, 0.06);
    border: 1px solid $colorGray300;
    color: $colorGray800;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 500;

    z-index: 200;
    button {
      border: none;
      background-color: transparent;
    }
    .drop-down-elements {
      display: flex;
      padding: 0.875rem 1.875rem 0.875rem 1rem;
      cursor: pointer;
      text-align: left;
    }
    .drop-down-elements:hover {
      background-color: $colorGray50
    }
    .selected-element {
      border-radius: 0.75rem;
      background: $colorGray100;
    }
    .selected-element:hover {
      background: $colorGray100;
    }
  }
}
