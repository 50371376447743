.author-contact-wrapper {
  display: flex;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.75rem;
  border: 1px solid $colorGray300;
  background: $colorWhite;
  cursor: pointer;
  img {

  }
  .link-href {
    overflow: hidden;
    color: $colorGray700;
    text-overflow: ellipsis;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
  &:hover {
    background-color: $colorGray50;
  }
  &:active {
    background-color: $colorGray100;
  }
}
