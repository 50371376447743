.scroll-to-top {
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  width: 5.5rem;
  height: 5.5rem;
  padding: 1.25rem;
  background-color: $colorGray900;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 600;

  img {
    transform: rotate(-90deg);
    width: 4.5rem;
    height: 4.5rem;
  }
  &:hover {
    background-color: darken($colorGray800, 10%);
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}